export function empty() {
    let value = {}
    return value = {
        cover_image: '', //封面
        title: '',
        content: '', //编译器内容
        time: '', //时间选择器
        file: '',
        url: '',
        video_url: '',
        original_url: '', //原文链接
        remark: '',
        medium_ids: '',
        package_ids: '',
    }
}